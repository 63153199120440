import React from 'react';
import img from '../img/img-4.jpg';
import icon from '../img/icon-4.svg';
import Navigation from './Navigation';
import Image from './Image';
import Icon from './Icon';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Fade from '@material-ui/core/Fade';
import { texts } from './data';

const styles = {
    text: {
        color: 'var(--text-color-light)'
    }
}

export default ({ quiz, clickNav, checkout, people}) => {
    
    const stepsArray = quiz.map(question => question.step);
    return (
        <Fade timeout={2000} in={true}>
        <Grid container spacing={0} direction="row" alignItems="stretch">
            <Hidden mdDown>
                <Grid item xl={2} lg={2}><Icon image={icon} /></Grid>
            </Hidden>
            <Hidden mdDown>
                <Grid item xl={2} lg={2}><Image image={img} /></Grid>
            </Hidden>
            
            
            <Grid item xl={5} lg={5} md={8} sm={7} xs={12}>
            <div id='question'>
                
                <Navigation names={[...stepsArray]} current={quiz.length} click={clickNav} />
                <h2 style={styles.text}>{texts.sliderHeadline}</h2>
                <p style={styles.text}>{texts.sliderText}</p>
                {people}
             </div>
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={5} xs={12}>{checkout}</Grid>
        </Grid>
        </Fade>
    );
}
    
