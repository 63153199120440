import React from 'react';
import Data, { currency, texts, orderNowButton } from './data';

const getCurrency = (cur = currency) => {
  if (cur.symbol !== '') {
    return String.fromCharCode(cur.symbol);
  }
  return cur.text;
}

const clickCallBack = (calculateFunc) => () => {
  orderNowButton(calculateFunc());
}

export default ({ results, resultCallback }) => {

  const total = [...results].reduce((total, item) => total += item.adds, 0);
  if (results.length === 0) {
    return (
      <div id='results-title' className='bg-warning'>
        <h2>{texts.resultHeadline}</h2>
        <p>{texts.resultText}</p>
      </div>
    );
  }

  return (
    <div id='results' className='bg-warning'>
      {
        [...results].map(answerLine => {
          return (
            <dl key={answerLine.question}>
              <dt><span className='answer'>{answerLine.question}</span><br />{answerLine.answer}</dt>
              <dd>+ {answerLine.adds.toLocaleString('ru-RU')} {getCurrency()}</dd>
            </dl>

          )
        })
      }
      <div className='total-answer'>
        {results.length < Data.length && <div className='total'>{total.toLocaleString('ru-RU')} {getCurrency()}</div>}
        {results.length === Data.length &&
          <div
            className='res-right btn btn-success btn-lg w-100 mt-3'
            onClick={clickCallBack(resultCallback)}
            >
              {texts.orderText} {total.toLocaleString('ru-RU')} {getCurrency()}{texts.period}
          </div>
        }
      </div>
    </div>
  )
}

