import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: '0 40px',
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const marks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 75,
    label: '75',
  },
  {
    value: 100,
    label: '100',
  },
];



function valuetext(value) {
  return `${value}°C`;
}


export default function DiscreteSlider({ handleChange }) {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.margin} />
      <Slider
        defaultValue={10}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-always"
        step={1}
        min={1}
        onChange={handleChange}
        marks={marks}
        valueLabelDisplay="on"
      />
    </div>
  );
}